export default [
    { headerName: 'ID', field: 'id' },
    { headerName: 'LEA', field: 'lea', initialHide: true, filter: false },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { headerName: 'Course Section ID', field: 'courseSectionId', filter: false },
    { headerName: 'Grading Period ID', field: 'gradingPeriodId', filter: false },
    {
        headerName: 'Current Enrollment',
        field: 'currentEnrollment',
        filter: false,
    },
    { headerName: 'Class Period', field: 'classPeriod', filter: false },
    { headerName: 'Start Date', field: 'startDate', filter: false },
    { headerName: 'End Date', field: 'endDate', filter: false },
]
